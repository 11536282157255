
  
  
  import FETCHING from '../../library/fetching'
  import DATAMASTER from '../../library/dataMaster'
  import UMUM from '../../library/umum'
  
  export default {
    data() {
      return {
  
        form : {
          id : '',
          urusan_kode : '',
          urusan_bidang_kode : '',
          utama : 0,
          unit_kerja_id : '',
        },

        kode : {
          id : '',
          unit_kerja_id : '',
          kode : '',
        },
  
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        autocomplete_db : '',
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        
        filterku : {
            urusan_kode : ''
        },

  
  
       
        list_data : [],
  
        page_first: 1,
        page_last: 0,
        page_limit : 10,
        cari_value: "",
        cek_load_data : true,
  
  
        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        btn_add: false,
        mdl_update_kode : false,
  
  
        FETCHING : FETCHING,
        DATAMASTER : DATAMASTER,
        UMUM : UMUM,
      }
    },
    methods: {
  
  
      getView : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_simpeg_unit_kerja + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
                urusan_kode : this.filterku.urusan_kode,
                urusan_bidang_kode : this.filterku.urusan_bidang_kode,
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data.data;
                this.page_last = res_data.jml_data;
                this.$store.commit("hideLoading");
                console.log(res_data);
        });
      },
  
  
      addData : function() {
        fetch(this.$store.state.url.URL_simpeg_unit_kerja + "addData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
            this.mdl_add = false
        });
      },
  
  
      editData : function(){
        fetch(this.$store.state.url.URL_simpeg_unit_kerja + "editData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
      },
  
      removeData : function(E){
        fetch(this.$store.state.url.URL_simpeg_unit_kerja + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });
  
      },
  
      selectData : function(data){
          this.form.unit_kerja_id = data.id;
          this.asyncFetchUrusanBidang(this.form.urusan_kode)

      },

      selectDataRelasi : function(data){
          console.log(data);
          this.form.id = data.id;
          this.form.urusan_bidang_kode = data.urusan_bidang_kode;
          this.form.unit_kerja_id = data.unit_kerja_id;
      },


    // ========================================================================================================================


      addDataKode : function() {
        fetch(this.$store.state.url.URL_simpeg_unit_kerja_kode + "checkUpdateData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
        });
      },
  

      selectKodefikasi : function(data){
          this.kode.id = data.id ;
          this.kode.unit_kerja_id = data.unit_kerja_id ;
          this.kode.kode = data.kode_unit_akun ;
      },
  
  
      // ====================================== CONTOH eDOC ====================================
      onChangex(data){
        // await DATAMASTER.postMasterUrusanBidang(val)
      },
      async asyncFetchUrusan(){
        await DATAMASTER.postMasterUrusan('');
        this.form.urusan_kode = this.$store.state.list_urusan[0].kode;
        this.asyncFetchUrusanBidang(this.form.urusan_kode)
      },
      async asyncFetchUrusanBidang(val){
        await DATAMASTER.postMasterUrusanBidang(val)
      },
      // ====================================== CONTOH eDOC ====================================
  
    // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_urusan_filter : function (val, update) {
          update(() => {
            if (val === '') {}
            else {FETCHING.getContohAtocomplete(val)}
          })
      },
  
      // ====================================== CONTOH AUTOCOMPLETE ====================================
  
  
  
  
  
  
  
  
      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
  
          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },

          indexing : function(index){
              var idx = ((this.page_first-1)*this.page_limit)+index
              return idx
          },
  
  
      // ====================================== PAGINATE ====================================
  
  
  
  
  
  
  
    },
  
    mounted () {
      FETCHING.getContohAtocomplete('')
      this.asyncFetchUrusan();
    //   DATAMASTER.postMasterUrusan('')
      this.getView();
    },
  }
  